<script setup lang='ts'>
import { ref } from 'vue';

import Blocks from '../blocks/Blocks.vue';

import type { Result } from '@/types/result';
import type { Filter } from '@/types/filter';

defineProps<{
    result: Result
    advice: Filter[]
}>()


const sectionsVisibility = ref<Record<string, boolean>>({
    Algemeen: true,
    Voorwaarden: true,
    Marketing: true,
});

// function toggleSection(section: string) {
//     sectionsVisibility.value[section] = !sectionsVisibility.value[section];
// }
</script>

<template>
    <template v-if="advice && advice.length > 0">
        <div class="block block--question">
            <span class="block__index"></span>
            <div class="block__content">
                <div class="flex">
                    <label for="#"> Ons advies </label>
                </div>
                <div class="container">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <h3 class="hidden">hidden</h3>
                                </td>
                                <template v-for="({ name }, index) in advice" :key="index">
                                    <td>
                                        <h3 class="header--name">{{ name ?? '-' }}</h3>
                                    </td>
                                </template>
                            </tr>
                            <tr>
                                <td>
                                    <hr class="m-none" />
                                    <h3 class="header--col">
                                        Algemeen
                                        <!-- <span class="icon" :class="{ open: sectionsVisibility.Algemeen }">▼</span> -->
                                    </h3>
                                </td>
                                <template v-for="(_item, index) in advice" :key="index">
                                    <td>
                                        <hr class="m-none" />
                                        <h3 class="hidden">hidden</h3>
                                    </td>
                                </template>
                            </tr>
                            <template v-if="sectionsVisibility.Algemeen">
                                <template v-for="(key, index) in Object.keys(advice[0].Algemeen)" :key="index">
                                    <tr>
                                        <td>{{ key }}</td>
                                        <template v-for="(adviceItem, i) in advice" :key="i">
                                            <td>
                                                <Blocks :item="adviceItem.Algemeen[key]" :modal-key="`general-${i}`" />
                                            </td>
                                        </template>
                                    </tr>
                                </template>
                            </template>
                            <tr>
                                <td>
                                    <hr />
                                    <h3 class="header--col">
                                        Voorwaarden
                                        <!-- <span class="icon" :class="{ open: sectionsVisibility.Voorwaarden }">▼</span> -->
                                    </h3>
                                </td>
                                <template v-for="(_item, index) in advice" :key="index">
                                    <td>
                                        <hr />
                                        <h3 class="hidden">hidden</h3>
                                    </td>
                                </template>
                            </tr>
                            <template v-if="sectionsVisibility.Voorwaarden">
                                <template v-for="(key, index) in Object.keys(advice[0].Voorwaarden)" :key="index">
                                    <tr>
                                        <td>{{ key }}</td>
                                        <template v-for="(adviceItem, i) in advice" :key="i">
                                            <td>
                                                <Blocks :item="adviceItem.Voorwaarden[key]"
                                                    :modal-key="`conditions-${i}`" />
                                            </td>
                                        </template>
                                    </tr>
                                </template>
                            </template>
                            <tr>
                                <td>
                                    <hr />
                                    <h3 class="header--col">
                                        Marketing
                                        <!-- <span class="icon" :class="{ open: sectionsVisibility.Marketing }">▼</span> -->
                                    </h3>
                                </td>
                                <template v-for="(_item, index) in advice" :key="index">
                                    <td>
                                        <hr />
                                        <h3 class="hidden">hidden</h3>
                                    </td>
                                </template>
                            </tr>
                            <template v-if="sectionsVisibility.Marketing">
                                <template v-for="(key, index) in Object.keys(advice[0].Marketing)" :key="index">
                                    <tr>
                                        <td>{{ key }}</td>
                                        <template v-for="(adviceItem, i) in advice" :key="i">
                                            <td>
                                                <Blocks :item="adviceItem.Marketing[key]"
                                                    :modal-key="`marketing-${i}`" />
                                            </td>
                                        </template>
                                    </tr>
                                </template>
                            </template>
                            <template v-if="result.chosen_options && result.chosen_options.length > 0">
                                <tr>
                                    <td>
                                        <hr />
                                        <h3 class="header--col">Vragen met opties</h3>
                                    </td>
                                    <template v-for="(_item, index) in advice" :key="index">
                                        <td>
                                            <hr />
                                            <h3 class="hidden">hidden</h3>
                                        </td>
                                    </template>
                                </tr>
                                <template v-for="(item, index) in result.chosen_options" :key="index">
                                    <tr>
                                        <td>{{ item.choice?.name }}</td>
                                        <td>{{ item.value }}</td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </template>
</template>
