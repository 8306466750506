import type { Result } from '@/types/result';
import { type Component, ref, computed, type ComputedRef, type Ref } from 'vue';
import { storage } from './storage';
import { INFORMATION_SECTION_LENGHT, type Form } from '@/view/repository';
import type { UseForm } from './form';

export const STEP_STORAGE_KEY = '__cw_step';

interface Callback<T> {
  (value?: T, id?: T, option?: string): Promise<Result>;
}

interface Stepper<T> {
  index: Ref<number>;
  current: ComputedRef<T>;
  hasNext: ComputedRef<boolean>;
  next: (choice: number) => void;
  hasPrev: ComputedRef<boolean>;
  prev: (choice: number) => void;
}

export interface StepType<T> {
  component: Component<T>;
  props?: T extends Component<infer P> ? Partial<P> : never;
  callback?: Callback<T>;
  values?: UseForm<Form> | Ref<number>;
}

export const index = ref<number>(storage.get(STEP_STORAGE_KEY) ?? 0);

export function Step<T>(
  component: Component | null,
  props?: T extends Component<infer P> ? Partial<P> : never,
  callback?: Callback<T>,
  values?: UseForm<Form> | Ref<number>
): StepType<T> {
  return {
    component,
    props,
    callback,
    values,
  };
}

const previousQuestionIds = ref<number[]>([]);

function getChoices(options: ComputedRef<any[]>) {
  return options.value
    .filter(({ props }) => props?.question?.id !== undefined)
    .map(({ props }) => props.question.choices)
    .flat();
}

function findIndexById(options: ComputedRef<any[]>, id: number) {
  return options.value.findIndex(({ props }) => props?.question?.id === id);
}

export function useStepper<T>(options: ComputedRef<T[]>): Stepper<T> {
  const current = computed(() => options.value[index.value]);
  const hasNext = computed<boolean>(() => index.value + 1 < options.value.length);

  function next(choice: number) {
    if (!hasNext.value) return;

    const choices = getChoices(options);
    // @ts-expect-error
    const currentQuestionId = options.value[index.value]?.props?.question?.id;
    const next_id = choices.find(({ id }) => id === choice)?.next_id;
    const nextIndex = findIndexById(options, next_id);

    if (nextIndex !== 0 && nextIndex !== -1) {
      index.value = nextIndex;
      if (index.value === options.value.length - 1) {
        storage.remove(STEP_STORAGE_KEY);
      }
    } else if (index.value <= INFORMATION_SECTION_LENGHT) {
      index.value++;
    } else {
      index.value = options.value.length - 1;
    }

    if (currentQuestionId !== undefined) {
      previousQuestionIds.value.push(currentQuestionId);
    }

    storage.set(STEP_STORAGE_KEY, index.value);
  }

  const hasPrev = computed<boolean>(() => previousQuestionIds.value.length > 0);

  function prev(choice: number) {
    const lastQuestionId = previousQuestionIds.value.length > 0 ? previousQuestionIds.value[previousQuestionIds.value.length - 1] : undefined;
    const choices = getChoices(options);
    const prev_id = choices.find(({ id }) => id === choice)?.prev_id;
    const prevIndexByPrevId = findIndexById(options, prev_id);

    if (prevIndexByPrevId && prevIndexByPrevId !== -1) {
      index.value = prevIndexByPrevId;
      storage.set(STEP_STORAGE_KEY, index.value);
      return;
    }

    if (lastQuestionId !== undefined) {
      const lastQuestionIndex = findIndexById(options, lastQuestionId);
      if (lastQuestionIndex !== -1) {
        previousQuestionIds.value.pop();
        index.value = lastQuestionIndex;
        storage.set(STEP_STORAGE_KEY, index.value);
        return;
      }
    }

    if (index.value > 0) {
      index.value--;
    }

    storage.set(STEP_STORAGE_KEY, index.value);
  }

  return { index, current, hasNext, next, hasPrev, prev };
}
