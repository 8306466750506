<script setup lang="ts">
import { RESULT_STORAGE_KEY } from '../repository';
import { computed, ref, watch } from 'vue';
import { api } from '@/services/api';
import { useForm } from '@/services/form';
import { storage } from '@/services/storage';
import { useModal } from '@/components/modal/modal';

import Success from './modals/Success.vue';
import Options from './end/Options.vue';
import Advice from './end/Advice.vue';

import type { Result } from '@/types/result';

const MAIL_SUCCESS_MODALKEY = 'success';

const props = defineProps<{ result?: Result }>();

defineEmits<{
  (e: 'send', value: string): void;
  (e: 'restart'): void;
}>();

const { open } = useModal();

const currentResult = ref<Result>();
const loading = ref<boolean>(true);
const sendLoading = ref<boolean>(false);

const advice = computed(() => currentResult.value?.filters);
const options = computed(() => currentResult.value?.options);

const lenght = computed(() => {
  if (advice.value?.length === 1) return 100;
  if (advice.value?.length === 2) return 60;
  return advice.value?.length * 30;
});

const procent = computed(() => `${lenght.value}%`);


const { form, errors, setErrors } = useForm({
  email: ''
});

async function show() {
  try {
    const { data } = await api.get<Result>(`questionaire/${props.result?.id}`);
    if (!data) return;

    storage.set(RESULT_STORAGE_KEY, data);
    currentResult.value = data;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

async function send() {
  sendLoading.value = true;

  try {
    const { data, status } = await api.patch<Result>(`questionaire/${currentResult.value.id}/share`, { email: form.value.email });
    if (status === 200) open(MAIL_SUCCESS_MODALKEY);
    if (!data) return;
    storage.set(RESULT_STORAGE_KEY, data);
    return data;
  } catch (error) {
    setErrors(error);
  } finally {
    sendLoading.value = false;
  }
}

watch(() => props.result, show, { immediate: true });
</script>

<template>
  <template v-if="loading">
    <div class="loader-container">
      <div class="loader"></div>
    </div>
  </template>
  <template v-else>
    <template v-if="!advice">
      <div class="section__advice">
        <div class="block block--question">
          <span class="block__index"></span>
          <div class="block__content">
            <div class="flex">
              <label for="#"> Resultaten </label>
            </div>
            <p>Geen resultaten gevonden</p>
            <button @click="$emit('restart')" class="button">
              Opnieuw beginnen
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.333 15.333">
                <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 13.333 13.333m0-13.333L1 14.333" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="section__advice">
        <Advice :advice :result />
        <Options :options />
        <div v-if="advice || options" class="mail--container">
          <div class="__content">
            <p class="__title">Resultaten nog eens rustig nalezen?</p>
            <span v-if="errors.has('email')" class="error">{{ errors.get("email")![0] }}</span>
            <div class="__input">
              <input type="text" class="--input" placeholder="Vul hier je e-mailadres in" v-model="form.email" />
              <button @click.prevent="send" class="button white">
                Verstuur
                <template v-if="sendLoading">
                  <div class="loader"></div>
                </template>
              </button>
            </div>
            <svg class="corner" width="739" height="139" viewBox="0 0 739 139" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect x="674.711" y="-55" width="1031.97" height="1031.97" rx="240" transform="rotate(45 674.711 -55)"
                stroke="#AFCCFF" stroke-width="88" />
            </svg>
          </div>
        </div>
      </div>
      <Success :modal-key="MAIL_SUCCESS_MODALKEY" @restart="$emit('restart')" />
    </template>
  </template>
</template>

<style lang="scss">
table:not(table.modal) {
  width: v-bind(procent);
}

@media (max-width: 998px) {
  table:not(table.modal) {
    width: calc(v-bind(procent) + 20%);
  }
}

.error {
  color: chocolate;
  margin-top: 4px;
}

.loader {
  margin-left: 20px;
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid;
  border-color: #085cf0 #0000;
  animation: l1 1s infinite;
}

@keyframes l1 {
  to {
    transform: rotate(.5turn)
  }
}

.icon {
  display: inline-block;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.icon.open {
  transform: rotate(180deg);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
