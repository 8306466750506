<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useModal } from '@/components/modal/modal';

import Zipcode from './modals/Zipcode.vue';

import type { UseForm } from '@/services/form';
import type { Form } from '../repository';
import type { Popup } from '@/types/question';

const ZIPCODE_MODEL_KEY = 'zipcode';

const values = defineModel<UseForm<Form>>({ required: true });

defineProps<{
  type: string;
  index: number;
  popup?: Popup
  formKey: string;
}>();

const infoElement = ref<HTMLInputElement>();

const { open } = useModal();

onMounted(() => infoElement.value?.focus());
</script>

<template>
  <div class="section__content">
    <div class="block block--question">
      <span class="block__index">{{ index }}</span>
      <div class="block__content">
        <div class="flex">
          <label for="start">Wat is {{ type }}?</label>
          <template v-if="popup">
            <a class="info" @click.prevent="open(ZIPCODE_MODEL_KEY)">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group">
                  <g id="Group_2">
                    <path id="Path"
                      d="M15.9988 10.6666C15.8148 10.6666 15.6655 10.8159 15.6668 10.9999C15.6668 11.1839 15.8162 11.3333 16.0002 11.3333C16.1842 11.3333 16.3335 11.1839 16.3335 10.9999C16.3335 10.8159 16.1842 10.6666 15.9988 10.6666"
                      stroke="#085CF0" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Path_2" fill-rule="evenodd" clip-rule="evenodd"
                      d="M16 28V28C9.372 28 4 22.628 4 16V16C4 9.372 9.372 4 16 4V4C22.628 4 28 9.372 28 16V16C28 22.628 22.628 28 16 28Z"
                      stroke="#085CF0" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Path_3" d="M16.0002 16V22.6667" stroke="#085CF0" stroke-width="2.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </g>
                </g>
              </svg>
            </a>
            <Zipcode :modal-key="ZIPCODE_MODEL_KEY" :popup />
          </template>
        </div>
        <input v-model="values.form.value[formKey]" type="text" id="start" :placeholder="`Type hier ${type}`"
          class="start--input" />
        <span v-if="values.errors.value.has(formKey)" class="error">{{ values.errors.value.get(formKey)![0] }}</span>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
.info {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  border-radius: 143px;
  background: #f4f4f4;
  padding: 1rem;
  height: 30px;
  width: 30px;
}

.error {
  color: chocolate;
  margin-top: 4px;
}
</style>