<script setup lang="ts">
import { useModal } from '@/components/modal/modal';
import TableInformation from '../modals/TableInfomation.vue';

import type { ResultTypes } from '@/types/filter';
import Accordion from '@/components/accordion/Accordion.vue';

defineProps<{
  modalKey: string;
  item?: ResultTypes;
}>();

const { open } = useModal();
</script>

<template>
  <template v-if="typeof item === 'string'">
    <p>{{ item }}</p>
  </template>
  <template v-else-if="typeof item === 'boolean'">
    <template v-if="item">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="checkmark-big-circle 1" clip-path="url(#clip0_141_1165)">
          <path id="Vector" d="M11.25 14.25L9 12" stroke="#323232" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path id="Vector_2" d="M15 10.5L11.25 14.25" stroke="#323232" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path id="Vector_3"
            d="M3 12V12C3 16.9706 7.02943 21 12 21H12C16.9706 21 21 16.9706 21 12V12C21 7.02943 16.9706 3 12 3H12C7.02943 3 3 7.02943 3 12Z"
            stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_141_1165">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </template>
    <template v-else>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="delete-disabled.2 1" clip-path="url(#clip0_141_1191)">
          <g id="Group">
            <path id="Vector" d="M14.8299 9.16992L9.16992 14.8299" stroke="#323232" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_2" d="M14.8299 14.8299L9.16992 9.16992" stroke="#323232" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_3"
              d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
              stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_141_1191">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </template>
  </template>
  <template v-else-if="item?.type === 'bullet'">
    <template v-for="(_item, index) in item.value" :key="index">
      <p class="--list">
        {{ _item.name }}
        <span v-if="!item.value[item.value.lenght - 1]">|</span>
      </p>
    </template>
  </template>
  <template v-else-if="item?.type === 'accordion'">
    <Accordion :accordion="item.value" />
  </template>
  <template v-else-if="item?.type === 'modal'">
    <template v-for="(i, v) in item.value" :key="v">
      <template v-if="Object.values(i).length === 1">
        <p class="--list">{{ i.name }}<span v-if="!i[i.lenght - 1]">|</span></p>
      </template>
      <template v-else>
        <p @click="open(`${modalKey}-${v}`)" class="flex">
          {{ i.name }}
          <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
              <g id="Group_2">
                <path id="Path"
                  d="M15.9988 10.6666C15.8148 10.6666 15.6655 10.8159 15.6668 10.9999C15.6668 11.1839 15.8162 11.3333 16.0002 11.3333C16.1842 11.3333 16.3335 11.1839 16.3335 10.9999C16.3335 10.8159 16.1842 10.6666 15.9988 10.6666"
                  stroke="#085CF0" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                <path id="Path_2" fill-rule="evenodd" clip-rule="evenodd"
                  d="M16 28V28C9.372 28 4 22.628 4 16V16C4 9.372 9.372 4 16 4V4C22.628 4 28 9.372 28 16V16C28 22.628 22.628 28 16 28Z"
                  stroke="#085CF0" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                <path id="Path_3" d="M16.0002 16V22.6667" stroke="#085CF0" stroke-width="2.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </g>
            </g>
          </svg>
        </p>
      </template>
      <TableInformation :modal-key="`${modalKey}-${v}`" :items="i" />
    </template>
  </template>
  <template v-else-if="typeof item === 'undefined' || typeof item === null">-</template>
  <template v-else-if="item === undefined || item === null">-</template>
  <template v-else-if="!item.option">-</template>
</template>

<style scoped lang="scss">
.flex {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    color: var(--dark);
  }
}

td {
  word-wrap: break-word;
}
</style>
