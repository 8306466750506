<script setup lang="ts">
import { ref } from 'vue';
import { useModal } from '@/components/modal/modal';

import QuestionInformation from './modals/QuestionInformation.vue';
import Popup from './modals/Popup.vue';

import type { Option, Question } from '@/types/question';

const { open, visible } = useModal();

const props = defineProps<{
  question: Question;
  index: number;
}>();

defineEmits<{ (e: 'restart'): void }>();

const value = defineModel<number | null>({
  set(value) {
    const choice = props.question.choices.find(({ id }) => id === value);
    if (choice && choice.next_id === null && choice && choice.popup !== null) {
      openPopup(choice.id);
      return;
    } else if (choice && choice.popup !== null) {
      openPopup(choice.id);
      return value;
    }

    return value;
  },
  required: true,
});

const option = defineModel<number | null>('option', { required: true })

const dropdownOpen = ref<boolean>(false)
const selectedOption = ref<string>()

function keyMap(event: Event & KeyboardEvent) {
  if (!(event instanceof KeyboardEvent)) return;
  if (!props.question.choices.map((_, idx) => idx + 1).includes(+event.key)) return;
  if (visible.value) return;

  value.value = props.question.choices[+event.key - 1].id;
}

document.addEventListener('keydown', keyMap);

const INFORMATION_MODALKEY = 'question-information';
const POPUP_MODALKEY = 'question-popup';

function openPopup(index: number) {
  open(`${POPUP_MODALKEY}_${index}`);
}

function selectOption(value: Option) {
  dropdownOpen.value = false
  option.value = value.id;
  selectedOption.value = value.value
}
</script>

<template>
  <div class="section__content">
    <div class="block block--question">
      <span class="block__index"> {{ index }}. </span>
      <div class="block__content">
        <div class="flex">
          <label class="label" for="#">
            {{ question.name }}
          </label>
          <template v-if="question.information">
            <a class="info" @click.prevent="open(INFORMATION_MODALKEY)">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group">
                  <g id="Group_2">
                    <path id="Path"
                      d="M15.9988 10.6666C15.8148 10.6666 15.6655 10.8159 15.6668 10.9999C15.6668 11.1839 15.8162 11.3333 16.0002 11.3333C16.1842 11.3333 16.3335 11.1839 16.3335 10.9999C16.3335 10.8159 16.1842 10.6666 15.9988 10.6666"
                      stroke="#085CF0" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Path_2" fill-rule="evenodd" clip-rule="evenodd"
                      d="M16 28V28C9.372 28 4 22.628 4 16V16C4 9.372 9.372 4 16 4V4C22.628 4 28 9.372 28 16V16C28 22.628 22.628 28 16 28Z"
                      stroke="#085CF0" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Path_3" d="M16.0002 16V22.6667" stroke="#085CF0" stroke-width="2.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </g>
                </g>
              </svg>
            </a>
          </template>
        </div>
        <div class="grid grid--two">
          <template v-for="(choice, idx) in question.choices" :key="idx">
            <div class="check">
              <input type="radio" :id="`option-${idx}`" name="question" v-model="value" :value="choice.id" />
              <label :for="`option-${idx}`">
                <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.333 11.667">
                    <path fill="none" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
                      d="m1.5 5.5 4.667 4.667L14.833 1.5" />
                  </svg>
                </span>
                <span class="text"> {{ choice.name }} </span>
                <span class="key">{{ idx + 1 }}</span>
                {{ choice.prev_id }}
                <template v-if="choice.options && choice.options.length && value === choice.id">
                  <div class="wrapper">
                    <div class="select" :class="{ open: dropdownOpen }">
                      <button class="trigger" @click="dropdownOpen = !dropdownOpen">{{ selectedOption ? selectedOption :
                        'Kies een optie' }}</button>
                      <ul class="options">
                        <template v-for="(item, index) in choice.options" :key="index">
                          <li @click="selectOption(item)" :value="item.id">{{ item.value }}</li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </template>
              </label>
            </div>
            <Popup :modal-key="`${POPUP_MODALKEY}_${choice.id}`" :choice="choice" @restart="$emit('restart')" />
          </template>
        </div>
      </div>
    </div>
  </div>
  <QuestionInformation :modalKey="INFORMATION_MODALKEY" :question="question" />
</template>




<style lang="scss">
.check {
  box-sizing: border-box;
}

.wrapper {
  grid-column: 1 / -1;
}

.select {
  width: 100%;
  position: relative;
}

.trigger {
  font-size: 16px;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  text-align: left;
}

.options {
  margin-top: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: none;
}

.options li {
  font-size: 14px;
  list-style-type: none;
  width: 100%;
  padding: 10px;
  margin: 0px;
  cursor: pointer;
}

.options li:hover {
  background-color: #f0f0f0;
}

.select.open .options {
  display: block;
}

.flex {
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px 0;
}

.label {
  align-self: center;
  margin: 0;
}

.info {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  border-radius: 143px;
  background: #f4f4f4;
  padding: 1rem;
  height: 30px;
  width: 30px;
}
</style>
