<script setup lang="ts">
import { ref } from 'vue';

export interface AccordionObject {
    title: string;
    description: string;
}

const accordion = defineModel<AccordionObject>('accordion', { required: true });

const show = ref<boolean>(false);

const toggle = () => {
    show.value = !show.value;
}
</script>

<template>
    <template v-if="!accordion.title">
        <div class="block block--compare line">
            <div class="block__header">
                <p class="block__text">
                    -
                </p>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="block block--compare line" :class="{ open: show }" @click.prevent="toggle">
            <div class="block__header">
                <p class="block__text">
                    {{ accordion.title }}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8">
                        <path fill="black"
                            d="M6.293 7.707a1 1 0 0 0 1.414 0l6-6A1 1 0 1 0 12.293.293L7 5.585 1.707.293A1 1 0 0 0 .387.21L.293.293a1 1 0 0 0 0 1.414l6 6Z" />
                    </svg>
                </p>
            </div>
            <div v-if="show" class="block__content">
                {{ accordion.description }}
            </div>
        </div>
    </template>
</template>


<style lang="scss">
.block {
    &--compare {
        margin: 0 0 24px 0;

        .block {
            &__header {
                display: flex;
                align-items: center;
                padding: 24px 0 0 0;
                border-top: 1px solid var(--Grey_300);

                .block {

                    &__text {

                        font-size: 16px;
                        font-weight: 500;
                        color: var(--Button_color_primary);

                    }

                }

                svg {

                    height: 12px;
                    width: 12px;
                    margin: 0 0 0 8px;
                    transform: scaleY(1);
                    transition: transform 0.2s ease-in-out;
                }
            }

            &__content {
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                padding: 24px 0;
            }

        }

        &.open {
            svg {
                transform: scaleY(-1);
            }
        }

    }
}
</style>
