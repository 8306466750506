<script setup lang='ts'>
import { useModal } from '@/components/modal/modal';

import MoreInformation from '../modals/MoreInformation.vue';

import type { Filter } from '@/types/filter';

defineProps<{ options: Filter[] }>()

const { open } = useModal();
</script>

<template>
    <template v-if="options && options.length > 0">
        <div class="block block--question">
            <span class="block__index"></span>
            <div class="block__content">
                <div class="flex">
                    <label for="#"> Opties </label>
                </div>
                <div>
                    <template v-for="(item, index) in options" :key="index">
                        <div class="options--card">
                            <div class="__item">
                                <div class="__name">
                                    <span class="__index"> {{ index + 1 }} </span>
                                    {{ item.name ?? '-' }}
                                </div>
                            </div>
                            <div class="__item">
                                <dl v-for="(items, value) in item.Algemeen" :key="value">
                                    <template v-if="items?.option ?? true">
                                        <dt>
                                            {{ value }}
                                        </dt>
                                        <dd>
                                            <template v-if="typeof items === 'string'">
                                                {{ items }}
                                            </template>
                                            <template v-else-if="typeof items === 'boolean'">
                                            </template>
                                            <template v-if="items?.type === 'bullet'">
                                                <template v-for="(item, index) in items.value" :key="index">
                                                    <p class="--list">
                                                        {{ item.name }}
                                                        <span v-if="index !== items.value.length - 1">|</span>
                                                    </p>
                                                </template>
                                            </template>
                                            <template v-if="items?.type === 'modal'">
                                            </template>
                                            <template v-if="items?.type === 'accordion'">
                                            </template>
                                            <template v-else-if="items === 'undefined' || items === null">-</template>
                                        </dd>
                                    </template>
                                </dl>
                            </div>
                            <div class="__item">
                                <!-- @vue-expect-error -->
                                <dl v-for="(items, value, indx) in item.Voorwaarden" :key="indx">
                                    <template v-if="indx === 0 || indx === 1 || indx === 2">
                                        <dt>{{ value }}</dt>
                                        <dd>
                                            <template v-if="typeof items === 'boolean'">
                                                <template v-if="items">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g id="checkmark-big-circle 1" clip-path="url(#clip0_141_1165)">
                                                            <path id="Vector" d="M11.25 14.25L9 12" stroke="#323232"
                                                                stroke-width="1.5" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path id="Vector_2" d="M15 10.5L11.25 14.25"
                                                                stroke="#323232" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                            <path id="Vector_3"
                                                                d="M3 12V12C3 16.9706 7.02943 21 12 21H12C16.9706 21 21 16.9706 21 12V12C21 7.02943 16.9706 3 12 3H12C7.02943 3 3 7.02943 3 12Z"
                                                                stroke="#323232" stroke-width="1.5"
                                                                stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_141_1165">
                                                                <rect width="24" height="24" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </template>
                                                <template v-else>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g id="delete-disabled.2 1" clip-path="url(#clip0_141_1191)">
                                                            <g id="Group">
                                                                <path id="Vector" d="M14.8299 9.16992L9.16992 14.8299"
                                                                    stroke="#323232" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                                <path id="Vector_2" d="M14.8299 14.8299L9.16992 9.16992"
                                                                    stroke="#323232" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                                <path id="Vector_3"
                                                                    d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
                                                                    stroke="#323232" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                            </g>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_141_1191">
                                                                <rect width="24" height="24" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </template>
                                            </template>
                                            <template v-else>
                                                {{ items }}
                                            </template>
                                        </dd>
                                    </template>
                                </dl>
                            </div>
                            <div class="__item">
                                <button class="button outline blue" @click="open(`more-info-${index}`)">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g id="plus-medium 1">
                                            <path id="Vector" d="M5 12H19" stroke="#085CF0" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path id="Vector_2" d="M12 19V5" stroke="#085CF0" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                    </svg>
                                    Meer informatie
                                </button>
                            </div>
                        </div>
                        <MoreInformation :modal-key="`more-info-${index}`" :item="item" />
                    </template>
                </div>
            </div>
        </div>
    </template>
</template>
